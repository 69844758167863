<template>
  <div>
    <b-notification
        v-model="isError"
        type="is-danger"
        aria-close-label="Close notification"
        :closable="false">
        <b>Error fetching Recipe Collection!</b><br />Try refreshing the page. If the error persists check your network connection or let BM know.
    </b-notification>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <div v-if="!isLoading && !isError" class="content m-5">
        <Keypress key-event="keyup" :modifiers="['ctrlKey']" :key-code="83" :preventDefault="true" @success="save"  />
        <div class="columns">
          <div class="column">
            <h1 class="title is-1">{{ title }}</h1>
          </div>
          <div class="column is-narrow">
            <b-field horizontal>
                <b-select v-model="recipeCollection.status" placeholder="Status" @input="save">
                    <option>draft</option>
                    <option>test</option>
                    <option>fix</option>
                    <option>live</option>
                  </b-select>
             </b-field>
         </div>
          <div class="column is-narrow">
            <b-button outlined type="is-primary" class="right" icon-left="check" @click="saveAndClose">Save & Close</b-button>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" class="right" icon-left="content-save" @click="save">Save</b-button>
          </div>
        </div>
        <div class="columns">
            <section id="general" class="column">
              <h2 class="subtitle is-3">General</h2>
              <b-field horizontal label="Name">
                <b-input v-model="recipeCollection.title" />
               </b-field>
              <b-field horizontal label="Description">
                <b-input maxlength="100" type="textarea" v-model="recipeCollection.description" @input="saveDebounced"/>
              </b-field>
              <b-field horizontal label="Position">
                <b-numberinput
                  v-model="recipeCollection.position"
                  placeholder="e.g. 1"
                  type="is-light"
                  controls-position="compact"
                  :min="1"
                  @input="saveDebounced">
                </b-numberinput>
              </b-field>
           </section>
           <section id="images" class="column is-one-third">
             <EditImages
               v-model="recipeCollection.images"
               @input="save"
             />
           </section>
         </div>
         <section id="recipes" class="mt-6">
           <EditRecipes
              :maxRecipes="20"
              :allowMultipleFreeRecipes="true"
              title="Recipes"
              v-model="recipeCollection.recipes"
              @input="save"
              @recipe-added="recipeAdded($event)"
              @recipe-removed="recipeRemoved($event)"
            />
         </section>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
//import sortBy from 'lodash/sortBy'
//import cloneDeep from 'lodash/cloneDeep'

import EditImages from "@/components/EditImages"
import EditRecipes from "@/components/EditRecipes"
import db from "@/firebase/db"
import firebase from 'firebase/app'


export default {
  name: 'RecipeCollectionView',
  components: {
    EditImages,
    EditRecipes,
    Keypress: () => import('vue-keypress'),
  },
  props: [
    'id'
  ],
  data() {
    return {
      isLoading: true,
      isError: false,
      recipeCollection: {}
    };
  },
  computed: {
    title() {
      if (this.recipeCollection.title == null) {
        return null;
      }
      return this.recipeCollection.title ?? "New Recipe Collection"
    },
    image() {
      if (this.recipeCollection.images == null) {
        return null;
      }
      return this.recipeCollection.images.length > 0 ? this.recipeCollection.images[0].src : null
    },
    
    
    displayRecipes() {
      var recipes = this.recipeCollection.recipes ?? {};

      // flatten the recipes map from firebase to an array
      // e.g. from {id: {title: bar}, ...} to [{id: foo, title: bar}, ...]
      var array = Object.keys(recipes).map(function(recipeID) {
        // create new object with the recipeID included
        var newObj = {id: recipeID};
        // add all the nested properties
        for (var property in recipes[recipeID]) {
          newObj[property] = recipes[recipeID][property];
        }
        return newObj;
      });

      // set our local array sorted by the recipe object's position to bgin with
      return array.sort((a, b) => a.position - b.position);

    }
  },
  created: function () {
    // fetch our recipe_collection
    db.collection('recipe_collections')
      .doc(this.id)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          this.recipeCollection = snapshot.data();
        }else {
          this.isError = true; // show our error
        }
        this.isLoading = false; // remove our loader
      })
      .catch((error) => {
        this.isError = true;
        this.$buefy.notification.open({type: 'is-danger', message: `Error fetching Recipe Collection: ${error}`})
      });
  },
  methods: {
    
    saveDebounced: debounce(function () {
        this.save()
      }, 1000),
    async saveAndClose(){
      this.save()
        .then(() => {
          this.$router.go(-1)
        })
    },
    async save(){
      db.collection('recipe_collections')
        .doc(this.id)
        .set(this.recipeCollection)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Recipe Collection successfully updated!`})
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving Recipe Collection: ${error}`})
        });
    },
    async recipeAdded(recipe){
      const entry = {};
      entry[`${this.id}`] = true; // TODO: store name & date and any other useful info here
      db.collection("recipe_recipe_collections").doc(recipe.id)
        .set(entry, {merge: true})
        .then(() => {
            // console.log("recipe_recipe_collections entry added!");
        })
        .catch((error) => {
            console.error("Error adding recipe_recipe_collections entry: ", error);
        });
    },
    async recipeRemoved(recipe){
      // use the delete sentinel to remove a field.
      const remove = {};
      remove[`${this.id}`] = firebase.firestore.FieldValue.delete();
      db.collection("recipe_recipe_collections").doc(recipe.id)
        .update(remove)
        .then(() => {
            // console.log("recipe_recipe_collections entry removed!");
        })
        .catch((error) => {
            console.error("Error removing recipe_recipe_collections entry: ", error);
        });
    },
    
  }
}


</script>

<style>
#preview {
  background-color: #F7F1EA;
}
.mt-minus {
  margin-top: -4rem !important;
}
.rounded-border{
    border-radius:20px;
}
</style>
